<template>
  <div class="fluid-safe-image" :class="fluid ? 'fluid-safe-image--fluid' : 'fluid-safe-image--fix'">
    <safe-img
      :src="src"
      :alt="alt"
      :placeholder="placeholder"
      class="bg-light-secondary fluid-safe-image mx-auto d-block"
    />
  </div>
</template>

<script>
import SafeImg from '@core/components/safe-img/SafeImg.vue';

export default {
  name: 'FluidSafeImg',
  components: { SafeImg },
  props: {
    src: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    fluid: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.fluid-safe-image {
  &--fix {
    .fluid-safe-image {
      object-fit: cover;
      height: 8.92rem;
      width: 8.92rem;
      border-radius: 1.2rem;
    }
  }
  &--fluid {
    height: 0;
    overflow: hidden;
    padding-top: 100%;
    box-sizing: border-box;
    position: relative;
    .fluid-safe-image {
      width: 100%;
      object-fit: cover;
      vertical-align: top;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-radius: 1.2rem;
    }
  }
}
</style>
